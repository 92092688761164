<template>
<div :class="{card: true, selected: isSelected }" :id="value" v-on:click="selectCard">
  <div :class="{'inner-card': true, selected: isSelected}">
    <div :class="{'card-value': true, selected: isSelected}">{{value}}</div>
  </div>
</div>
</template>

<script>
export default {
  name: "Card",
  props: {
    value: String
  },
  data: function () {
    return {isSelected: false};
  },
  methods: {
    selectCard() {
      this.$emit('selected', { cardValue: this.value});
      this.isSelected = !this.isSelected;
    }
  }
}
</script>

<style scoped>

.card {
  float: left;
  background-image: url("../../src/assets/rainforrest.jpg");
  background-size: cover;
  width: 50vw;
  height: 80vh;
  cursor: pointer;
  border: #2c3e50 0.3em solid;
  border-radius: 0.15em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card.selected {
  float: none;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
}

.inner-card {
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  border: white 0.3em solid;
  background: black;
  width: 40vw;
  height: 40vw;
}

.card-value {
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30vw;
  font-weight: bold;
}

/*.card-value.selected {*/
/*  font-size: 30vh;*/
/*}*/

</style>
