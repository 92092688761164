<template>
  <div>
    <h1>Impressum</h1>

    <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
    <p>Thomas Wehner<br />
      Am Findorffhof 81<br />
      28879 Grasberg</p>

    <h4>Kontakt</h4>
    <p>Telefon: +49 (0) 170 2117695<br />
      E-Mail: info@consensus-cards.de</p>

    <p>Quelle: <a href="https://www.e-recht24.de">eRecht24</a></p>
  </div>
</template>

<script>
export default {
  name: "Impressum"
}
</script>

<style scoped>

</style>
