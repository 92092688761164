<template>
  <div id="app">
    <div v-show="header">
      <h1>Consensus-Cards.de</h1>
    </div>
    <card-set :start=0 :end=10 @hideHeader="hideHeader" v-show="showImpressum === false && showHelp === false" />
    <footer>
      <welcome v-show="showHelp" />
      <impressum v-show="showImpressum" />
      <button class="button black" @click="toggleHelp">Hilfe</button>
      <button class="button black" @click="toggleImpressum">Impressum</button>
      <div>&copy; 2021 by Thomas Wehner - Version 0.1.0</div>
    </footer>
  </div>
</template>

<script>
import CardSet from './components/CardSet.vue'
import Welcome from "@/components/Welcome";
import Impressum from "@/components/Impressum";

export default {
  name: 'App',
  components: {
    Impressum,
    Welcome,
    CardSet
  },
  data() {
    return { header: true, showHelp: false, showImpressum: false };
  },
  methods: {
    toggleHelp: function() {
      this.showHelp = !this.showHelp;
    },
    hideHeader: function() {
      this.header = !this.header;
    },
    toggleImpressum: function () {
      this.showImpressum = !this.showImpressum;
    }
  }
}
</script>

<style>
@import "~normalize.css/normalize.css";
@import "~milligram/dist/milligram.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}
#app h1 {
  margin-top: 1em;
}

footer button {
  position: relative;
  margin-top: 1em;
  vertical-align: center;
}
</style>
