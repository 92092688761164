<template>
  <div class="card-set">
      <div v-for="card in cardset" :key="card">
        <Card :value=card @selected="selectCard" v-show="selectedCard === '' || selectedCard === card" />
      </div>
      <Card value="X" @selected="selectCard" v-show="selectedCard === '' || selectedCard === 'X'" />
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  name: "CardSet",
  components: {Card},
  comments: {
    Card
  },
  props: {
    start: {
      type: Number,
      required: true
    },
    end: {
      type: Number,
      required: true
    }
  },
  computed: {
    cardset: function() {
      let cards = [];
      for(let i = this.start; i <= this.end; i++) {
        cards.push("" + i);
      }
      return cards;
    }
  },
  data() {
    return {
      selectedCard: ''
    }
  },
  methods: {
    selectCard: function(scard) {
      if (scard.cardValue === this.selectedCard) {
        this.$emit('hideHeader', { hide: false});
        this.selectedCard = '';
      } else {
        this.$emit('hideHeader', { hide: true});
        this.selectedCard = scard.cardValue;
      }
    }
  }
}
</script>

<style scoped>
  .card-set {
    width: 100vw;
  }
</style>
