<template>
  <div class="welcome">
    <h3>Vor-Ort Konsensierungen</h3>
    <p>
      Diese Seite soll Dir helfen, schnell und einfach eine Konsensierungs-Karte anzuzeigen. <br />
      Klicke dazu einfach auf die Karte mit deinen persönlichen Widerstand und halte z.B. dein Smartphone mit der Karte drauf hoch.<br />
    </p>
    <h3>Online Konsensierungen</h3>
    <p>In Planung</p>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
